.page-not-found {
  &__actions {
    .home-icon {
      svg {
        path {
          fill: none !important;
        }
      }
    }
  }
}
