.estimated-event-modal {
  .ant-modal-content {
    padding-right: 0 !important;
    .ant-modal-body {
      padding-top: 24px !important;
      padding-bottom: 0 !important;
      .shipment-est-list {
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #9ca3b04d;
          border-radius: 8px;
        }
      }
    }
  }
}