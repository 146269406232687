.sm-details-header {
  font-family: "Nunito Sans";
  font-style: normal;
  display: flex;
  justify-content: space-between;
  flex-direction: inherit;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 101;

  &-left {
    display: flex;
    align-items: center;

    &-back {
      display: flex;
    }

    &-info {
      display: flex;
      flex-direction: column;
    }

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #1f2937;
    }

    span.sm-id {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7280;
    }
  }

  &-right {
    display: flex;

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      background: #f2f3f4;
      border-radius: 8px;
      margin-left: 8px;
      // gap: 8px;
      outline: none;
      border: none;
      transition: none !important;

      > span {
        margin-left: 0 !important;
      }

      &:focus,
      &:visited {
        color: initial;
        background: #f2f3f4;
      }

      // &[disabled] {
      //   cursor: not-allowed;
      //   svg path {
      //     stroke: rgba(0, 0, 0, 0.25);
      //   }
      // }
      > span.anticon {
        margin-right: 8px;
      }

      // &:hover,
      // &.active {
      //   background: #6085ff;
      //   color: #fff;
      //   svg path {
      //     fill: #fff;
      //   }
      // }
    }
  }
}

.shipment_sm-general-info-container {
  /* shipment = sm */
  .sm-tab {
    .sm-top {
      padding: 24px;
      position: relative;
      padding-left: 56px;

      .back {
        position: absolute;
        left: 24px;
        top: 24px;
      }

      .sm-top-title {
        color: var(--gray-800);
      }

      .sm-top-time {
        color: var(--gray-500);
      }
    }

    .sl-align-center {
      max-width: 75px;
      margin: 0 auto;
      white-space: nowrap;
    }

    .btn-follow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 2px 20px;
      border-radius: 4px;
      margin-top: 0px;
      height: 32px;
      background: var(--gray-100);
      border-radius: 8px;

      // position: absolute;
      // top: 24px;
      // right: 24px;
      &.disabled {
        cursor: not-allowed;
        color: var(--gray-400) !important;
        background: var(--gray-100) !important;
        border-color: var(--gray-100) !important;
      }

      &.primary {
        color: #fafafd;
        background: var(--blue-500);
        border-color: var(--blue-500);

        &:hover {
          background: var(--blue-600);
        }
      }

      &.default {
        color: var(--gray-800);
        background: var(--gray-100);
        border-color: var(--gray-100);

        &:hover {
          background: var(--gray-200);
        }
      }
    }

    .danger-status {
      color: var(--red-500);
    }

    .sm-item {
      .sm-copy {
        background: var(--gray-100);
        border-radius: 4px;
        width: 24px;
        height: 24px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }

      .sm-head-1 {
        padding: 16px;
        background: #fff !important;
      }

      .sm-cost {
        text-align: center;
        color: var(--blue-500);
      }

      .sm-head {
        .sm-head-content {
          padding: 16px;
          background: rgba(51, 51, 51, 0.04);
          border-radius: 12px;
        }

        .logo {
          position: relative;
        }

        .sm-head-status {
          .ant-tag {
            margin-bottom: 4px;
          }
        }

        .sm-head-item {
          .sm-head-item-label {
            @apply sm_body_b3_reg;
            color: var(--gray-500);
          }

          .sm-head-item-value {
            @apply sm_body_b2_semi;
            color: var(--gray-800);
            margin-top: 4px;
            display: flex;
            align-items: center;
          }

          small {
            color: var(--gray-500);
          }
        }

        .sm-follow-txt {
          text-align: right;
          color: var(--red-500);
        }
      }

      .sm-body {
        padding: 24px;
        background: #fff;

        .sm-steps {
          .ant-steps-item {
            line-height: 12px;

            &:last-child {
              width: fit-content;
            }

            padding: 0;

            &.success .ant-steps-item-content .ant-steps-item-title:after {
              border-top: 2px solid var(--primary-200);
            }

            // &.failure .ant-steps-item-content .ant-steps-item-title:after {
            //   border-top: 2px solid var(--red-200);
            // }

            &.dashed .ant-steps-item-content .ant-steps-item-title:after {
              border-top: 2px dashed var(--gray-300);
            }

            .ant-steps-item-icon {
              margin: 0;
              background: none !important;
            }

            .ant-steps-item-content {
              position: relative;

              .ant-steps-item-title {
                min-height: 32px;
                padding: 0;

                &:after {
                  background: none;
                  border-top: 2px solid var(--primary-200);
                }
              }
            }
          }
        }
      }

      .sm-more {
        padding: 0 16px 16px 16px;
        color: var(--blue-500);
      }
    }
  }
}

.sm-loading-general-info {
  min-height: 230px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
}

@media (max-width: 992px) {
  .shipment_shipment-list-container {
    /* shipment = sm */
  }
}

@media (max-width: 800px) {
  .shipment_sm-general-info-container
    .sm-tab
    .sm-item
    .sm-body
    .sm-steps.shipment-item__events-wrapper
    .ant-steps-item
    .ant-steps-item-tail:after {
    width: 0 !important;
    background: none !important;
    border: 1px solid var(--primary-200) !important;
  }

  .shipment_sm-general-info-container
    .sm-tab
    .sm-item
    .sm-body
    .sm-steps.shipment-item__events-wrapper
    .ant-steps-item.dashed
    .ant-steps-item-tail:after {
    width: 0 !important;
    background: none !important;
    border: 1px dashed var(--gray-300) !important;
  }
}
