@import "./variables.scss";
@font-face {
  font-family: "NunitoSans-Bold";
  src: url("../webfonts/NunitoSans-Bold.ttf");
}
body {
  font-size: 14px;
  font-family: "Nunito Sans";
  color: var(--gray-800);
  background: #FAFAFD;
}

.skyviews {
  input.form-control {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    background: #ffffff;
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 50px;
  }
  img {
    max-width: 100%;
  }
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f2f2f2;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    z-index: 9999;
    &-icon {
      display: flex;
    }
  }
  .nav-item {
    &.active {
      font-weight: bold;
    }
  }
  .hidden {
    display: none;
  }
  .root-layout {
    &__wrapper {
      display: flex;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .login {
    background-image: url("../images/login-bg.png");
    text-align: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .login,
  .form-user-container {
    form {
      max-width: var(--maxWidthForm);
      margin: auto;
      .forgot-password {
        cursor: pointer;
        text-decoration: underline;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #2e5eab;
      }
      .form-group {
        text-align: left;
        em {
          line-height: 19px;
          font-style: normal;
          color: #eb5757;
        }
        .input-group-custom {
          position: relative;
          .right-action {
            position: absolute;
            cursor: pointer;
            right: 15px;
            top: 30%;
          }
        }
      }
    }
    .logo {
      display: flex;
      flex-shrink: 0;
    }
  }
  .Toastify__toast {
    min-height: 40px;
  }
  .Toastify__toast--success {
    height: 40px;
    background-color: #ffffff;
    border: 1px solid #29cb97;
    box-sizing: border-box;
    border-radius: 4px;
    color: #333333;
    padding: 8px 16px;
    .Toastify__toast-body {
      font-weight: 500;
      font-size: 16px;
      line-height: 0;
    }
    .Toastify__close-button {
      color: #29cb97;
      display: flex !important;
      align-items: center !important;
      align-self: center !important;
    }
  }
  .Toastify__toast--warning {
    @extend .Toastify__toast--success;
    border: 1px solid var(--yellow-600);
    .Toastify__close-button {
      color: var(--yellow-600);
    }
  }
  .Toastify__toast--error {
    @extend .Toastify__toast--success;
    border: 1px solid #d5412c;
    .Toastify__close-button {
      color: #d5412c;
    }
  }
}
.ant-breadcrumb {
  a:hover {
    color: var(--console-primary);
  }
}
.custom-modal {
  .ant-radio-wrapper {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }
  .ant-image {
    width: 100%;
  }
  .ant-image-img {
    max-height: 367px;
    border-radius: 8px;
  }
  .screen-name {
    background: #f4f4f4;
    border-radius: 4px;
    font-weight: bold;
    color: #110f24;
    font-size: 16px;
    line-height: 24px;
  }
  .screen-image {
    box-shadow: 0px 8px 24px rgba(128, 128, 128, 0.32);
  }
}
.shipment-graph-view-toolbar {
  left: 8px !important;
  bottom: 8px !important;
  background: rgba(51, 51, 51, 0.04) !important;
  border-radius: 12px !important;
  padding: 8px !important;
  height: fit-content !important;
  border: unset !important;
  top: unset !important;
  color: var(--blue-300) !important;
  li[code="redo"],
  li[code="undo"],
  li[code="realZoom"],
  li[code="realZoom"] {
    display: none !important;
  }
  li {
    width: unset !important;
    height: unset !important;
  }
  li[code="zoomOut"],
  li[code="zoomIn"] {
    margin-right: 8px !important;
  }
  svg {
    width: 16px !important;
    height: 16px !important;
    fill: currentColor;
    opacity: 1 !important;
  }
}
.g6-tooltip {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  padding: 8px !important;
  width: fit-content !important;
  background: var(--gray-600) !important;
  border-radius: 4px !important;
  border: unset !important;
  box-shadow: unset !important;
  color: #fff !important;
}
.ant-breadcrumb {
  span:last-child {
    pointer-events: none !important;
    @apply sm_body_b2_semi;
  }
}
.ant-input {
  @apply sm_body_b3_reg;
}
.ant-notification-notice {
  background: var(--green-50);
  border: 1px solid #30cd9a;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 12px 32px rgba(96, 97, 112, 0.24);
  border-radius: 8px;
  padding: 15px 11px 11px 14px;
  .ant-notification-notice-message {
    @apply sm_sub_title_semi;
    margin-left: 32px;
  }
  .ant-notification-notice-with-icon {
    span {
      display: none;
    }
    &:before {
      content: "";
      width: 25px;
      height: 25px;
      background: url("https://console-images.s3.ap-southeast-1.amazonaws.com/shipments/check-circle-icon.svg")
        center center no-repeat;
      z-index: 999;
      position: absolute;
    }
  }
  .ant-notification-close-x {
    position: relative;
    top: -3px;
    svg {
      color: var(--gray-800);
      font-size: 13px;
      font-weight: bold;
    }
  }
}
.ant-tooltip {
  .ant-tooltip-arrow-content {
    background-color: var(--gray-600);
    &::before {
      background-color: var(--gray-600);
    }
  }
  .ant-tooltip-inner {
    max-width: unset;
    background-color: var(--gray-600);
    @apply sm_body_b2_reg;
  }
}

.shipment-tooltip.last-tooltip {
  .ant-tooltip-content {
    margin-right: -15px !important;
  }
  .ant-tooltip-arrow {
    right: 1px !important;
  }
}
.shipment-tooltip.left-tooltip {
  .ant-tooltip-arrow {
    &-content {
      width: 16px;
      height: 16px;
    }
  }
}
.console .last-tooltip .ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 1px !important;
}
.console .last-tooltip .ant-tooltip-placement-topRight .ant-tooltip-content {
  margin-right: -15px !important;
}

.ant-select-dropdown {
  width: fit-content !important;
}

.console-pagination {
  .console-pagination-item-active a,
  .ant-pagination-item-active a {
    color: var(--blue-500);
  }
}

.btn-filter-custom:not(.disabled) {
  &:hover {
    background: var(--blue-50) !important;
    color: var(--blue-500) !important;
    border: 1px solid var(--blue-200) !important;
    cursor: pointer;
    svg {
      color: var(--blue-500) !important;
    }
  }
}

.hide-footer-modal {
  .ant-modal-footer {
    display: none;
  }
}

.export-btn {
  .ant-btn-loading-icon {
    margin-right: 10px;
  }
}
#anchor-list {
  position: relative;
  top: -65px;
}

.default-wrapper {
  padding: 16px;
  box-sizing: border-box;
}

.show1line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
 

.container-ids-modal {
  .ant-modal-body {
    padding: 0;
    padding-bottom: 0 !important;
    padding-top: 24px !important;
  }
  .ant-modal-footer {
    display: none;
  }
}