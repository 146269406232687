.time-range-event-modal {
  .ant-modal-content {
    padding-right: 4px !important;
    padding-bottom: 0 !important;
    .ant-modal-body {
      padding-top: 24px !important;
      .shipment-date-time-list {
        padding-right: 20px;
        .label {
          @apply sm_body_b1_reg;
          color: var(--gray-500);
        }
        .value {
          @apply sm_sub_title_semi;
          color: var(--gray-800);
        }

        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #9ca3b04d;
          border-radius: 8px;
        }
      }
    }
  }
}
