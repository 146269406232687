.shipment-item__events {
  &-item {
    &:last-child {
      width: 180px;
    }

    .ant-steps-item {
      &-content {
        width: calc(100% - 30px);
      }

      &-icon {
        .ant-steps-icon {
          display: flex;
          align-items: center;
        }
      }
    }

    &--info {
      max-width: unset;
      position: relative;
      left: -24px;
    }

    .sm-step-name {
      @apply sm_body_b1_semi;
      display: flex;
      align-items: center;
      color: var(--gray-800);
    }

    .sm-step-desc {
      @apply sm_body_b2_reg;
      color: var(--gray-600);

      &.danger {
        color: var(--red-500);
      }
    }

    &--day-between-container {
      @apply text-gray800 sm_body_b2_reg;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 7px;

      .day-between {
        background: white;
        padding: 0 10px;
        z-index: 3;
      }
    }
  }
}

.estimated-event-modal,
.in-transit-event-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 24px !important;
      padding-bottom: 0 !important;
      .shipment-est-list {
        &::-webkit-scrollbar {
          width: 8px;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #9ca3b04d;
          border-radius: 8px;
        }
      }
    }
  }
}

.delivered-estimation-accuracy-modal,
.in-transit-event-modal {
  .ant-modal-content {
    .ant-modal-body {
      padding-top: 24px !important;
      padding-bottom: 0 !important;
    }
  }
}

.shipment-tooltip {
  .ant-tooltip-arrow {
    right: 0.928932px;
    z-index: -1;
  }

  .ant-tooltip-inner {
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
  }
}

@media (max-width: 800px) {
  .shipment-item__events-wrapper {
    overflow: hidden;

    .shipment-item__events-item .ant-steps-item-content {
      width: 100%;
    }
    &.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
      margin-left: 140px !important;
    }
    .ant-steps-item-tail {
      left: 154px !important;
    }
    .ant-steps-item-custom {
      width: 100%;
      max-width: 320px;
    }
    .shipment-item__events-item--day-between-container {
      text-align: right;
      right: 185px;
      top: 50px;
    }
    .shipment-item__events-item--info {
      left: 180px;
      top: -35px;
    }
    &.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
      overflow: visible !important;
    }
    .shipment-item__events-item:last-child {
      width: 100% !important;
    }
    .ant-steps-item-title:after {
      border-left: 2px solid var(--primary-200);
    }
  }
}
@media (max-width: 500px) {
  .shipment-item__events-wrapper {
    .sm-step-desc {
      word-break: break-word;
      max-width: 100%;
      display: flex;
      width: 130px;
    }
  }
}
