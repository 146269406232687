@function getTextColor($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter backgorund, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}

:root {
  --drawerExpandWidth: 241px;
  --drawerCollapseWidth: 40px;
  --gutter: 2rem;
  --supernova: #ffce04;
  --silver-chalice: #9e9e9e;
  --skyviews-primary: #2e5eab;
  --tundora: #464646;
  --mine-shaft: #333333;
  --alabaster: #f9f9f9;
  --wild-sand: #f4f4f4;
  --blue-ribbon: #1e6cff;
  --mercury: #e1e1e1;
  --alto: #d4d4d4;
  --gallery: #ededed;
  --titanwhite: #e7ecff;
  --dodgerblue: #3d84ff;
  --gray: #8f8f8f;
  --gray1: #909090;
  --gray2: #c4c4c4;
  --gray3: #eaeaea;
  --gray4: #f4f4f4;
  --lemon: #f6ed0a;
  --screamingreen: #79ff43;
  --dodgerblue1: #35a8ff;
  --electricviolet: #cf18df;
  --dustygray: #979797;
  --redorange: #ff2626;
  --yellow: #f5ff00;
  --pizazz: #ff9300;
  --blue: #3b00fc;
  --riceflower: #f2ffec;
  --silver: #bebebe;
  --mystic: #e3e9ed;
  --boulder: #747474;
  --rice-flower: #edffe6;
  --aqua-haze: #f0f4f7;

  /* Switch button */
  --switchButtonHeight: 30px;
  --switchButtonBorderRadius: 15px;
  --switchButtonTextColor: #fff;
  --switchButtonSelectedSideBackgroundColor: #fff;
  --switchButtonActiveBackgroundColor: #68bd45;
  --switchButtonUnActiveBackgroundColor: #d6412d;
  /* Checkbox*/
  --checkBoxBorderColor: #c3c3c3;
  --checkBoxBackgroundColor: #fff;

  --checkBoxHoverBackgroundColor: #fff;

  --checkBoxCheckedBorderColor: #fff;

  --checkBoxDisabledColor: #8e8e8e;
  --checkBoxDisabledBorderColor: #efefef;
  --checkBoxDisabledBackgroundColor: #efefef;

  /* Drawer container */

  --drawerContainerBackgroundColor: #464646e0;
  --drawerHeaderContainCollapseHoverBackgroundColor: #4f5050cf;
  --drawerMenuDrawerIcon: white;
  --drawerMenuItemColor: #fff;
  --drawerMenuItemActiveBackgroundColor: #fff;
  --drawerMenuItemActiveTextColor: #000;

  --primaryOpacity: #68bd4533;

  --navHeight: 72px;
  --maxWidthForm: 365px;

  --sm-main-font-family: "Nunito Sans";

  --blue-800: #4f71e3;
  --blue-700: #3958bf;
  --blue-600: #4f71e3;
  --blue-500: #6085ff;
  --blue-400: #7897ff;
  --blue-300: #9db3fd;
  --blue-200: #bfcefe;
  --blue-100: #d8e1fe;
  --blue-50: #eff3ff;

  --light-blue-700: #2774ae;
  --light-blue-600: #0284c7;
  --light-blue-500: #0ea5e9;
  --light-blue-400: #24bbed;
  --light-blue-300: #7dd3fc;
  --light-blue-200: #bae6fd;
  --light-blue-100: #e0f2fe;
  --light-blue-50: #e9f8fd;

  --green-700: #047857;
  --green-600: #059669;
  --green-500: #10b981;
  --green-400: #30cd9a;
  --green-300: #6ee7b7;
  --green-200: #a7f3d0;
  --green-100: #d1fae5;
  --green-50: #ecfdf5;

  --yellow-700: #a16207;
  --yellow-600: #ca8a04;
  --yellow-500: #fdb614;
  --yellow-400: #fec400;
  --yellow-300: #fde047;
  --yellow-200: #fef08a;
  --yellow-100: #fef8ce;
  --yellow-50: #fefce8;

  --orange-700: #c2410c;
  --orange-600: #ea580c;
  --orange-500: #f97316;
  --orange-400: #ff9425;
  --orange-300: #fdba74;
  --orange-200: #fed7aa;
  --orange-100: #ffedd5;
  --orange-50: #fff7ed;

  --red-700: #b91c1c;
  --red-600: #dc2626;
  --red-500: #ef4444;
  --red-400: #f87171;
  --red-300: #fca5a5;
  --red-200: #fecaca;
  --red-100: #fee2e2;
  --red-50: #fef2f2;

  --pink-700: #be185d;
  --pink-600: #db2777;
  --pink-500: #f03aaa;
  --pink-400: #f472b6;
  --pink-300: #f9a8d4;
  --pink-200: #fbcfe8;
  --pink-100: #fce7f3;
  --pink-50: #fdf2f8;

  --purple-700: #7e22ce;
  --purple-600: #9333ea;
  --purple-500: #b558f6;
  --purple-400: #c084fc;
  --purple-300: #d8b4fe;
  --purple-200: #e9d5ff;
  --purple-100: #f3e8ff;
  --purple-50: #faf5ff;

  --gray-800: #1f2937;
  --gray-600: #4b5563;
  --gray-500: #6b7280;
  --gray-400: #9ca3af;
  --gray-300: #d1d5db;
  --gray-200: #e5e7eb;
  --gray-100: #f3f4f6;
  --gray-50: #f9fafb;

  --green-700: #15803d;
  --green-600: #16a34a;
  --green-500: #22c55e;
  --green-400: #4ade80;
  --green-300: #86efac;
  --green-200: #bbf7d0;
  --green-100: #dcfce7;
  --green-50: #f0fdf4;

  /* */

  --primary-900: #222;
  --primary-700: #222;
  --primary-600: #3665fd;
  --primary-500: #6085ff;
  --primary-400: #222;
  --primary-300: #222;
  --primary-200: #bfcefe;
  --primary-100: #222;
  --primary-50: #222;

  --secondary-900: #4f71e3;
  --secondary-700: #222;
  --secondary-600: #222;
  --secondary-500: #eff3ff;
  --secondary-400: #222;
  --secondary-300: #222;
  --secondary-200: #e5e7eb;
  --secondary-100: #d8e1fe;
  --secondary-50: #fafafd;

  --primary-text-900: #fff;
  --primary-text-700: #fff;
  --primary-text-600: #fff;
  --primary-text-500: #000;
  --primary-text-400: #fff;
  --primary-text-300: #fff;
  --primary-text-200: #000;
  --primary-text-100: #fff;
  --primary-text-50: #fff; 

  --secondary-text-900: #fff;
  --secondary-text-700: #fff;
  --secondary-text-600: #fff;
  --secondary-text-500: #000;
  --secondary-text-400: #fff;
  --secondary-text-300: #fff;
  --secondary-text-200: #000;
  --secondary-text-100: #fff;
  --secondary-text-50: #000;

  --header--background--color: #eff3ff;
}
.primary-text-color {
  // $base-color: var(--primary-500);
  // color: getTextColor($base-color);
}
