.wrapper-section {
  padding: 16px;
  display: flex;
  flex-direction: column;
  &-border {
    width: 100%;
    background: #ffffff;
    border: 1px solid var(--blue-100);
    box-sizing: border-box;
    border-radius: 16px;
    padding: 16px;
    height: 100%;
  }
  &-shadow {
    background: #ffffff;
    box-shadow: 0px 2px 4px rgb(40 41 61 / 4%), 0px 4px 16px rgb(96 97 112 / 16%);
    border-radius: 12px;
    margin-top: 24px;
    width: 100%;
  }
}
