.console-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  font-weight: 400;
  border: 1px solid transparent;
  border-radius: 4px;

  &__size {
    &-extra-small {
      font-size: 12px;
      line-height: 16px;
      padding: 2px 4px;
      height: 20px;

      .console-tag__left-icon {
        width: 12px;
        height: 12px;
      }
    }

    &-small {
      .console-tag__content {
        @apply sm_body_b3_reg;
      }
      height: 28px;
      font-size: 12px;
      line-height: 16px;

      .console-tag__left-icon {
        width: 12px;
        height: 12px;
      }
    }

    &-middle {
      .console-tag__content {
        @apply sm_body_b2_reg;
      }

      .console-tag__left-icon {
        width: 16px;
        height: 16px;
      }
    }

    &-large {
      font-size: 16px;
      line-height: 24px;

      .console-tag__content {
        @apply sm_body_b1_reg;
      }

      .console-tag__left-icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__theme {
    &-default {
      background-color: var(--grey-200);
      border-color: var(--grey-50);
      color: var(--gray-800);
    }

    &-purple {
      background-color: var(--purple-100);
      border-color: var(--purple-100);
      color: var(--purple-600);
    }

    &-yellow {
      background-color: var(--yellow-100);
      border-color: var(--yellow-100);
      color: var(--orange-600);
    }

    &-green {
      background-color: var(--green-100);
      border-color: var(--green-100);
      color: var(--green-600);
    }

    &-blue {
      background-color: var(--blue-100);
      border-color: var(--blue-100);
      color: var(--blue-600);
    }

    &-red {
      background-color: var(--red-100);
      border-color: var(--red-100);
      color: var(--red-600);
    }

    &-grey {
      background-color: rgba(51, 51, 51, 0.04);
      border-color: rgba(51, 51, 51, 0.04);
      color: var(--grey-800);
    }

    &-disabled {
      background-color: rgba(51, 51, 51, 0.04);
      border-color: rgba(51, 51, 51, 0.04);
      color: var(--grey-400);
    }
  }

  &__left-icon {
    margin-right: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}