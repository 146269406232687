.sm-tooltip-exception {
    .ant-tooltip-content {
      .ant-tooltip-inner {
        padding: 0;
        border-radius: 4px;
        .exception-content__wrapper {
          &::-webkit-scrollbar {
            width: 8px;
            border-radius: 8px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 8px;
          }
          &::-webkit-scrollbar-thumb {
            background: var(--gray-400);
            border-radius: 8px;
          }
        }
      }
    }
  }