.shipment_shipment-detail-container {
  display: flex;
  padding-bottom: 16px;
  gap: 16px;
  .sm-detail-left {
    flex: 1;
  }
  .sm-detail-right {
    min-width: 315px;
  }
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  opacity: 0;
  display: none;
  background-color: transparent;
}
.ant-drawer-open {
  width: fit-content !important;
}
.ant-drawer-content-wrapper {
  height: calc(100% - 160px) !important;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 4px 16px rgba(96, 97, 112, 0.16);

  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 16px;
      border-bottom: none;
      .ant-drawer-close {
        padding: 16px;
      }
    }
    .ant-drawer-body {
      padding: 0;
      overflow: hidden;
    }
  }
}
 