.switch-button-container {
  .switch-button-item {
    &.active {
      box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.08), 0px 0.5px 2px rgba(96, 97, 112, 0.16);
      color: #1f2937 !important;
      background-color: #fff;
    }
    &:hover:not(.active) {
      color: var(--blue-600) !important;
    }
  }
}
