.sm-detail-container-list {
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 4px 16px rgba(96, 97, 112, 0.16);
  padding: 24px;
  background-color: #ffffff;

  .sm-header {
    display: flex;
    justify-content: space-between;

    .sm-header-search-wrapper {
      display: flex;
      align-items: center;
      gap: 16px;

      .title {
        white-space: nowrap;
      }

      .sm-custom-search-input {
        border-radius: 0;
        background-color: #fafafd;
        border: 1px solid var(--gray-200) !important;
        border-right: none;
        border: none;
        min-width: 280px;
        &:active,
        &:hover,
        &:focus {
          border-color: var(--blue-500) !important;
          box-shadow: 0px 0px 0px 2px rgba(96, 133, 255, 0.3);
        }

        .ant-input {
          @apply sm_body_b2_reg;
          background-color: #fafafd;
        }
      }
    }

    .sm-price-wrapper {
      display: flex;
      align-items: center;
      color: var(--blue-500);
      gap: 4px;
    }
  }

  .sm-space-vertical {
    width: 100%;
  }

  .sm-container-list {
    border: 1px solid var(--secondary-200);
    border-radius: 12px !important;
    padding: 12px 8px;

    .header-item {
      padding: 0;
      display: flex;
      gap: 4px;
      flex-direction: column;
      justify-items: flex-start;
      
      .title {
        color: var(--gray-500);
      }

      .sub-title {
        color: var(--gray-800);
      }

      .container-id-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;

        .sub-title {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &.price {
        padding-right: 16px;
        text-align: right;

        .sub-title {
          color: var(--blue-500);
        }
      }
    }

    .ant-collapse-item {
      border: 1px solid var(--secondary-200);
      border-radius: 12px !important;
      overflow: hidden;

      .ant-collapse-header {
        cursor: pointer;
        align-items: center;
        border-radius: 12px;
        background-color: var(--secondary-50);
        padding: 12px 8px;

        .ant-collapse-arrow {
          margin-right: 8px;
          font-size: unset;
          width: 16px;
          height: 16px;
        }

        

        .sm-panel-header-left {
          flex-basis: 50%;
          display: flex;

          .header-item {
            &.name {
              flex-basis: 60%;
            }
          }
        }

        .sm-panel-header-right {
          display: flex;
          justify-content: space-between;
          flex: auto;

          &.price {
            padding-right: 16px;
          }
        }
      }

      .custom-icon {
        margin: 8px 4px 8px 16px;
        transition: 0.3s;
      }

      &-active {
        border-color: var(--secondary-200);
        overflow: hidden;

        .ant-collapse-header {
          background-color: var(--secondary-50);
        }
      }
    }

    .ant-collapse-header {
      display: flex;
      align-items: center;
    }
  }

  &-table {
    .ant-table {
      color: var(--gray-800);

      .ant-table-row {
        box-shadow: inset 0px -1px 0px #e5e7eb;

        &:last-child {
          box-shadow: unset;
        }
      }

      .content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .title {
          color: var(--gray-500);
        }

        .sub-title {
          color: var(--gray-800);
        }

        &.price {
          text-align: right;
        }
      }
    }
  }
}

.tracking-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-modal-footer {
    display: none;
  }
}
